import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {  animeFadeUp, animeFadeIn } from "../utils/Animations"

const ContactPage = ({ transitionStatus }) => {

  return (
    <Layout>
      <SEO
        title="Contactez-nous - Belladrinks bar à cocktails événementiel"
        description="Vous organisez un mariage, un événement professionnel: contactez notre équipe et obtenez un devis rapidement."
      />
      <section className="py-16 md:py-40 2xl:py-56 mt-20">
        <div className="main-container">
          <div>
            <ContactForm transitionStatus={transitionStatus}/>
          </div>
        </div>
      </section>
      <section className="py-16 md:py-40 2xl:py-56 bg-bigstone-light">
        <div className="main-container">
          <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                ref={ref}
              >
                <motion.h1 variants={animeFadeIn}  className="display-1 mb-4 text-white">
                  Contactez-nous
                </motion.h1>
                <motion.p variants={animeFadeIn} className="lead text-white">N'hésitez pas à nous contacter, que vous souhaitiez travailler avec nous ou simplement pour nous poser une question.</motion.p>
              </motion.div>
            )}
          </InView>
          <InView triggerOnce={true} threshold={0.10} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.1 }
                  }
                }}
                className="grid mt-20 gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 text-white"
              >
                <motion.div variants={animeFadeUp}>
                  <h3 className="display-3 mb-2">Téléphone</h3>
                  <div className="border-b-4 border-copper mt-2 mb-4 w-10"></div>
                  <div className="text-xl">
                    <p>09 72 54 56 40 / 06 84 09 76 93</p>
                    <p>Lundi au vendredi de 9h00 à 17h00</p>
                  </div>
                </motion.div>
                <motion.div variants={animeFadeUp}>
                  <h3 className="display-3 mb-2">Email</h3>
                  <div className="border-b-4 border-copper mt-2 mb-4 w-10"></div>
                  <div className="text-xl">
                    <p>contact@belladrinks.com</p>
                    <p>recrutement@belladrinks.com</p>
                  </div>
                </motion.div>
                <motion.div variants={animeFadeUp}>
                  <h3 className="display-3 mb-2">Siège social</h3>
                  <div className="border-b-4 border-copper mt-2 mb-4 w-10"></div>
                  <div className="text-xl">
                    <p>Belladrinks SAS</p>
                    <p>84 chemin du Grand Revoyet</p>
                    <p>69230 Saint-Genis-Laval</p>
                  </div>
                </motion.div>
                <motion.div variants={animeFadeUp}>
                  <h3 className="display-3 mb-2">Rejoignez-nous</h3>
                  <div className="border-b-4 border-copper mt-2 mb-4 w-10"></div>
                  <div className="text-xl">
                    <p>
                      <a className="hover:text-copper-light transition duration-300" href="https://fr-fr.facebook.com/Belladrinks" target="_blank" rel="noreferrer">Facebook</a>
                    </p>
                    <p>
                      <a className="hover:text-copper-light transition duration-300" href="https://www.instagram.com/belladrinks" target="_blank" rel="noreferrer">Instagram</a>
                    </p>
                    <p>
                      <a className="hover:text-copper-light transition duration-300" href="https://www.linkedin.com/company/belladrinks" target="_blank" rel="noreferrer">Linkedin</a>
                    </p>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </InView>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

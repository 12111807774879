const animeFadeUp = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeOut",
      duration: "0.6"
    }
  },
  hidden: {
    opacity: 0,
    y: '40px',
    transition: {
      ease: "easeOut",
      duration: "0.6"
    }
  }
}
const animeFadeIn = {
  visible: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: "0.6"
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: "0.6"
    }
  }
}
const animeSlideOutRight = {
  visible: {
    x: '-100%',
    transition: {
      ease: [0.87, 0, 0.13, 1],
      duration: "1"
    }
  },
  hidden: {
    x: 0
  }
}
const animeSlideOutTop = {
  visible: {
    y: '-100%',
    transition: {
      ease: [0.87, 0, 0.13, 1],
      duration: "1"
    }
  },
  hidden: {
    y: 0
  }
}
const animeSlideOutBot = {
  visible: {
    y: '100%',
    transition: {
      ease: [0.87, 0, 0.13, 1],
      duration: "1"
    }
  },
  hidden: {
    y: 0
  }
}
const animeSlideOutLeft = {
  visible: {
    x: '100%',
    transition: {
      ease: [0.87, 0, 0.13, 1],
      duration: "1"
    }
  },
  hidden: {
    x: 0
  }
}

export {animeFadeUp, animeFadeIn, animeSlideOutRight, animeSlideOutLeft, animeSlideOutTop, animeSlideOutBot}
import React from 'react'

const ContactFormInput = (props) => {
  return (
    <>
      <input
        className={`transition duration-300 text-lg p-4 h-14 mt-0 block w-full border-2 border-gray-200 focus:ring-0 focus:border-copper  ${props.errors ? 'border-red-600' : ''}`}
        type={props.type}
        id={props.id}
        name={props.name}
        ref={props.register}
        placeholder={props.placeholder}
      />
    </>
  )
}

export default ContactFormInput
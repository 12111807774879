import React, {useState, useRef} from "react"
import { useForm } from "react-hook-form"
import ContactFormInput from "./ContactFormInput"
import axios from "axios"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {  animeFadeUp, animeFadeIn } from "../../utils/Animations"

const ContactForm = ({transitionStatus}) => {
  const formEl = useRef(null);
  const callbackEl = useRef(null);
  const [sendingState, setSendingState] = useState(null)
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = data => {
    setSendingState('sending')
    axios.post('/.netlify/functions/sendForm', {
      data
    })
      .then(function (response) {
        setSendingState('success')
        formEl.current.reset()
        callbackEl.current.focus()
      })
      .catch(function (error) {
        setSendingState('fail')
        callbackEl.current.focus()
      })
  }
  const eventOptions = [
    { value: 'Mariage', label: 'Mariage'},
    { value: 'Événement professionnel', label: 'Événement professionnel'},
    { value: 'Événement privé', label: 'Événement privé'},
    { value: 'Activation de marque', label: 'Activation de marque'},
    { value: 'Atelier cocktails', label: 'Atelier cocktails'},
    { value: 'Autre', label: 'Autre'},
  ]
  return (
    <div>
      <form ref={formEl} onSubmit={handleSubmit(onSubmit)}>
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              ref={ref}
            >
              <motion.h2 variants={animeFadeIn}  className="display-1 mb-4">
                Parlez-nous de votre projet
              </motion.h2>
              <motion.p variants={animeFadeIn} className="lead">Utilisez le formulaire ci-dessous pour votre demande de devis.</motion.p>
            </motion.div>
          )}
        </InView>
        <InView triggerOnce={true} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              ref={ref}
              variants={animeFadeUp}
              className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-20">
              <div className="flex flex-col">
                <label className="mb-3 label" htmlFor="name">Nom *</label>
                <ContactFormInput
                  name="name"
                  placeholder="Entrez votre nom"
                  id="name"
                  type="text"
                  errors={errors.name}
                  register={register({
                    required: "Veuillez compléter ce champs"
                  })}
                />
                {errors.name && <span className="mt-2 font-bold">{errors.name.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="mb-3 label" htmlFor="firstname">Prénom *</label>
                <ContactFormInput
                  name="firstname"
                  placeholder="Entrez votre prénom"
                  id="firstname"
                  type="text"
                  errors={errors.firstname}
                  register={register({
                    required: "Veuillez compléter ce champs"
                  })}
                />
                {errors.firstname && <span className="mt-2 font-bold">{errors.firstname.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="label mb-4" htmlFor="phone">Téléphone *</label>
                <ContactFormInput
                  name="phone"
                  id="phone"
                  type="text"
                  placeholder="Entrez votre téléphone"
                  errors={errors.phone}
                  register={register({
                    required: "Veuillez compléter ce champs",
                    pattern: {
                      value: /^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$/i,
                      message: "Veuillez entrer un numéro de téléphone valide"
                    }
                  })}
                />
                {errors.phone && <span className="mt-2 font-bold">{errors.phone.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="mb-3 label" htmlFor="email">Email *</label>
                <ContactFormInput
                  name="email"
                  id="email"
                  type="text"
                  placeholder="Entrez votre email"
                  errors={errors.email}
                  register={register({
                    required: "Veuillez compléter ce champs",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Veuillez entrer une adresse e-mail valide"
                    }
                  })}
                />
                {errors.email && <span className="mt-2 font-bold">{errors.email.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="label mb-4" htmlFor="event_type">Type d'événement *</label>
                <select
                  ref={register}
                  name="event_type"
                  id="event_type"
                  className={`transition duration-300 text-lg bg-white p-4 h-14 mt-0 block w-full border-2 border-gray-200 focus:ring-0 focus:border-copper`}>
                  {eventOptions.map((option) =>
                    <option key={option.value} value={option.value}>{option.value}</option>
                  )}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="label mb-4" htmlFor="place">Lieu *</label>
                <ContactFormInput
                  name="place"
                  id="place"
                  type="text"
                  placeholder="Entrez le lieu de l'événement"
                  errors={errors.place}
                  register={register({
                    required: "Veuillez compléter ce champs"
                  })}
                />
                {errors.place && <span className="mt-2 font-bold">{errors.place.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="label mb-4" htmlFor="date">Date *</label>
                <ContactFormInput
                  name="date"
                  id="date"
                  type="date"
                  placeholder="Entrez la date de l'événement"
                  errors={errors.date}
                  register={register({
                    required: "Veuillez compléter ce champs",
                    pattern: {
                      value: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/i,
                      message: "Veuillez entrer un numéro de téléphone valide"
                    }
                  })}
                />
                {errors.date && <span className="mt-2 font-bold">{errors.date.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="label mb-4" htmlFor="participants">Nombre de participants *</label>
                <ContactFormInput
                  name="guests"
                  id="guests"
                  type="text"
                  placeholder="Entrez le nombre de participants"
                  errors={errors.guests}
                  register={register({
                    required: "Veuillez compléter ce champs"
                  })}
                />
                {errors.guests && <span className="mt-2 font-bold">{errors.guests.message}</span>}
              </div>
              <div className="flex flex-col md:col-span-2">
                <label className="mb-4 label" htmlFor="informations">Informations complémentaires *</label>
                <p className="mb-4 text-lg">Donnez nous le plus de détails possible (horaires de service, nombre de cocktails par personne, budget etc.)</p>
                <textarea
                  className={`transition duration-300 h-64 text-lg bg-white p-4 h-14 mt-0 block w-full border-2 border-gray-200 focus:ring-0 focus:border-copper ${errors.information ? 'border-red-600' : ''}`}
                  name="informations"
                  id="informations"
                  ref={register({
                    required: "Veuillez compléter ce champs"
                  })}
                >
            </textarea>
                {errors.informations && <span className="mt-2 font-bold">{errors.informations.message}</span>}
              </div>
              <div className="flex flex-col">
                <label>
                  <input
                    className="transition duration-300 mr-2 h-6 w-6 border-gray-400 border-2 text-copper shadow-sm focus:border-none focus:ring focus:ring-copper"
                    type="checkbox"
                    name="rgpd"
                    ref={register({
                      required: "Veuillez accepter les conditions"
                    })}
                  />
                  <span className="text-lg">
                  J'accepte que les informations saisies soient utilisées dans le cadre de la demande de contact
                  et de la relation commerciale qui peut en découler.
                  </span>
                </label>
                {errors.rgpd && <span className="mt-2 font-bold">{errors.rgpd.message}</span>}
              </div>
              <div className="flex justify-end">
                <div>
                  <button
                    type="submit"
                    className={`button inline-flex items-center text-white hover:bg-white hover:text-copper ${sendingState === 'sending' ? 'bg-gray-400' : 'bg-copper'}`}
                    disabled={sendingState === 'sending'}
                  >
                    <svg
                      className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${sendingState === 'sending' ? 'block' : 'hidden'}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    {
                      sendingState === 'sending' ? 'Envoie en cours' : 'Envoyer'
                    }
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </InView>
      </form>
      <div tabIndex="-1" ref={callbackEl} className={` text-center bg-white p-8 md:p-20 mt-10 ${sendingState === 'fail' || sendingState === 'success' ? 'block': 'hidden'}`}>
        <div className="flex justify-center mb-10">
          {sendingState === 'fail' &&
            <svg className="fill-current text-red-600" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"><path d="M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-4.971 19.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25zm.5-4.25h-1l-1-10h3l-1 10z"/></svg>
          }
          {sendingState === 'success' &&
            <svg className="fill-current text-copper" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"><path d="M24 1l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-4.638 6.251c.99-.576 1.864-1.296 2.638-2.17v2.053c-.667.588-1.391 1.096-2.175 1.527l-.463-1.41zm-4.883 3.06c-.978.125-2.355.188-3.479.188v-1.455c1.032 0 2.327-.056 3.229-.169l.25 1.436zm4.184-1.086l-.055.023c-.879.369-1.831.655-2.865.859l-.25-1.438c.982-.197 1.885-.473 2.714-.833l.456 1.389z"/></svg>
          }
        </div>
        <h2 className="display-3 mb-4">
          {
            sendingState === 'fail' ? 'Erreur lors de l\'envoi du message' : ''
          }
          {
            sendingState === 'success' ? 'Message envoyé !' : ''
          }
        </h2>
        <p className="lead mb-6 max-w-2xl mx-auto">
          {
            sendingState === 'fail' ? 'Désolé, votre message n\'a pas pu être envoyé.\n' +
              'Veuillez réessayer de soumettre le formulaire.\n' +
              'Si le problème persiste écrivez-nous: contact@belladrinks.com' : ''
          }
          {
            sendingState === 'success' ? 'Merci de nous avoir contacté, nous reviendrons vers vous le plus rapidement possible.' : ''
          }
        </p>
      </div>
    </div>

  )
}

export default ContactForm
